<template>
  <div>
    <v-dialog v-model="dialog" scrollable persistent max-width="500">
      <v-card style="border-radius:5px">
        <v-card-title primary-title class='primary white--text flex-column align-start'>
          <span class="text-subtitle">Avaliação</span>
          <span class="text-subtitle-2">Aluno: {{student_enrolled.name}}</span>
        </v-card-title>
        
        <card-loading-transparent v-if="loading"></card-loading-transparent>
        <v-card-text v-if="!loading" class="pt-3">

          <v-form class="mt-6" ref="studentEnrollmentForm" :readonly="!can_be_graded">
            <span class="text-subtitle-2">Aluno frequente?</span>
            <v-switch v-model="enrolled.was_frequent" :label="enrolled.was_frequent ? 'Sim' : 'Não'"></v-switch>
            <v-text-field :label="`Nota final`" :rules="[ruleMaxGrade(max_grade)]" type="number" v-model="enrolled.grade"></v-text-field>
            <v-textarea label="Comentário" rows="2" v-model="enrolled.comment"></v-textarea>
          </v-form>
          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn color="error" @click="closeDialog"><v-icon left>mdi-close</v-icon>Fechar</v-btn>
          <v-btn color="primary" @click="submitStudentEnroll" v-if="!this.loading" :disabled="!can_be_graded"><v-icon left>mdi-clipboard-text</v-icon>Avaliar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'
import validations from '@/mixins/validations';

export default {
  name: 'DialogStudentRating',
  mixins: [
    validations
  ],

  props: {
    dialog: {
      type: Boolean,
      default: false 
    },
    student_enrolled: {
      type: Object,
      default: null
    },
    max_grade: {
      type: Number,
      default: null
    },
    can_be_graded:{
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      enrolled:{
        grade: '',
        was_frequent: false,
        comment: ''
      }
    }
  },

  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    setStudentToEnroll(){
      this.enrolled = {...this.student_enrolled};
      this.loading = true
      if(this.enrolled.id != null){
        setTimeout(() => {
          this.loading = false
        }, 300);
      }
    },
    submitStudentEnroll(){
      if (this.$refs.studentEnrollmentForm.validate()) {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          title: `Deseja realizar esta operação ?`,
          icon: 'question',
        }).then(r=>{
          if(r.isConfirmed){
            let enrolled = this.prepareStudentEnroll()
            this.updateStudentEnroll(enrolled)
          };
        });
      }
    },
    updateStudentEnroll(enrolled){
      Api.Enrolled.update(enrolled).then((r) => {
        this.$emit('doneUpdate', {enrolled: enrolled, response_status: r.status})
        this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
      }).catch((err) =>{
        this.addNotification({type: 'error', msg: `Erro: ${err}`})
      })
    },
    prepareStudentEnroll(){
      let enrolled = {
        ...this.enrolled,
        id: this.student_enrolled.id
      }
      return enrolled
    },
    ...mapActions({
      addNotification: 'Notification/add'
    }),
  },
  mounted () {
    this.setStudentToEnroll()
  },
  computed: {
    ...mapState({
    })
  },
}
</script>

<style scoped>

</style>